import {ALERT_SHOW} from "@/store/actions/alert";

import flatBudgetAPI from "@/utils/axios/flat_budget"
import {
    CREATE_FLAT_BUDGET,
    DELETE_FLAT_BUDGET,
    GET_ALL_FLAT_BUDGETS,
    SELECT_FLAT_BUDGET,
    UPDATE_FLAT_BUDGET
} from "@/store/actions/flat_budget";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const state = { flat_budgets: [], select_flat_budgets: [], select_loaded: false }

const getters = {
    getFlatBudgets: state => state.flat_budgets,
    getSelectFlatBudgets: state => state.select_flat_budgets,
}

const actions = {
    [GET_ALL_FLAT_BUDGETS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            flatBudgetAPI.get_all(payload)
                .then(response => response.data)
                .then(data => {
                    commit(GET_ALL_FLAT_BUDGETS, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
    [CREATE_FLAT_BUDGET]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatBudgetAPI.create(payload)
                .then(response => response.data)
                .then(data => {
                    commit(CREATE_FLAT_BUDGET, data)
                    dispatch(SELECT_FLAT_BUDGET)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [UPDATE_FLAT_BUDGET]: ({ commit }, payload) => {
        commit(START_MODAL_LOADING)
        return new Promise((resolve, reject) => {
            flatBudgetAPI.update(payload)
                .then(response => response.data)
                .then(data => {
                    commit(UPDATE_FLAT_BUDGET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [DELETE_FLAT_BUDGET]: ({ dispatch, commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(START_MODAL_LOADING)
            flatBudgetAPI.remove(payload)
                .then(response => response.data)
                .then(data => {
                    commit(DELETE_FLAT_BUDGET, data)
                    dispatch(SELECT_FLAT_BUDGET)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
                .finally(() => commit(STOP_MODAL_LOADING))
        })
    },
    [SELECT_FLAT_BUDGET]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            flatBudgetAPI.select(payload)
                .then(response => response.data)
                .then(data => {
                    commit(SELECT_FLAT_BUDGET, data)
                    resolve(true)
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                    reject(false)
                })
        })
    },
}

const mutations = {
    [GET_ALL_FLAT_BUDGETS]: (state, payload) => {
        state.flat_budgets = payload
    },
    [CREATE_FLAT_BUDGET]: (state, payload) => {
        state.flat_budgets.push(payload)
    },
    [UPDATE_FLAT_BUDGET]: (state, payload) => {
        let obj = state.flat_budgets.find(item => item.id === payload.id)
        Object.keys(payload).forEach(item => {
            obj[item] = payload[item]
        })
    },
    [DELETE_FLAT_BUDGET]: (state, payload) => {
        const obg = state.flat_budgets.find(item => item.id === payload.id)
        const obj_id = state.flat_budgets.indexOf(obg)
        if (obg) {
            state.flat_budgets.splice(obj_id, 1)
        }
    },
    [SELECT_FLAT_BUDGET]: (state, payload) => {
        state.select_flat_budgets = payload
        state.select_loaded = true
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}