import {
    CREATE_ACCOUNTING_TAB_MENU_ITEMS,
    DELETE_ACCOUNTING_TAB_MENU_ITEMS,
    MINIMIZE_ACCOUNTING_TAB_MENU_ITEMS,
    MAXIMIZE_ACCOUNTING_TAB_MENU_ITEMS
} from '@/store/actions/accounting/tab_menu'

const state = { accounting_menu_items: [] }

const getters = {
    getAccountingTabMenuItems: state => state.accounting_menu_items
}

const actions = {
    [CREATE_ACCOUNTING_TAB_MENU_ITEMS]: ({commit}, payload) => {
        commit(CREATE_ACCOUNTING_TAB_MENU_ITEMS, payload)
    },
    [DELETE_ACCOUNTING_TAB_MENU_ITEMS]: ({commit}, payload) => {
        commit(DELETE_ACCOUNTING_TAB_MENU_ITEMS, payload)
    },
    [MINIMIZE_ACCOUNTING_TAB_MENU_ITEMS]: ({commit}, payload) => {
        commit(MINIMIZE_ACCOUNTING_TAB_MENU_ITEMS, payload)
    }
}

const mutations = {
    [CREATE_ACCOUNTING_TAB_MENU_ITEMS]: (state, payload) => {
        const obj = state.accounting_menu_items.find(item => item.dialog_id === payload.dialog_id)
        if (!obj) {
            state.accounting_menu_items.push(payload)
        } else {
            obj.minimized = false
        }
    },
    [MINIMIZE_ACCOUNTING_TAB_MENU_ITEMS]: (state, payload) => {
        const obj = state.accounting_menu_items.find(item => item.dialog_id === payload)
        if (obj) {
            obj.minimized = true
        }
        console.log(obj, 'MINIMIZE_ACCOUNTING_TAB_MENU_ITEMS')
    },
    [MAXIMIZE_ACCOUNTING_TAB_MENU_ITEMS]: (state, payload) => {
        const obj = state.accounting_menu_items.find(item => item.dialog_id === payload)
        if (obj) {
            obj.minimized = false
        }
    },
    [DELETE_ACCOUNTING_TAB_MENU_ITEMS]: (state, payload) => {
        const obg = state.accounting_menu_items.find(item => item.dialog_id === payload)
        const obj_id = state.accounting_menu_items.indexOf(obg)
        if (obg) {
            state.accounting_menu_items.splice(obj_id, 1)
        }
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
